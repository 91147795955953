<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Edit Data Pengajuan Pemberian Pinjaman'" />

    <div>
      <!-- <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
      ></VueFileAgent>
      <button-form
        :submit="submitLoanLender"
        :buttonText="'Upload'"
        class="pt-8"
      />

      <v-divider class="my-6" /> -->

      <h4 class="pb-4">Form Input</h4>
      <form-loan-lender :formLoanLender="formNewLoanLender" />
      <button-form :submit="submitLoanLender" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperUser from "../../components/Stepper/StepperUser.vue";
import PopupRole from "../../components/Popup/PopupRole.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormLoanLender from "../../components/Form/FormLoanLenderEdit.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperUser,
    PopupRole,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormLoanLender,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      loading: false,
      showDialog: false,
      showSuccessDialog: false,
      showErrDialog: false,
      formNewLoanLender: {
        idPinjaman: null,
        borrower: null,
        penyelenggara: "",
        noPerjanjianLender: "",
        lender: null,
        tanggalPerjanjianLender: "",
        tanggalPenawaranPemberianPinjaman: "",
        noVaLender: "",
        nilaiPenawaranPinjaman: 0,
        nilaiPenawaranDisetujui: 0,
        report: null,
      },
    };
  },

  async mounted() {
    await store.dispatch("options/fetchLoanApplications");
    await store.dispatch("office/fetchLoanLender", this.$route.params.id);
    this.formNewLoanLender = {
      idPinjaman: store.state.office.currentLoanLender.id_pinjaman,
      borrower: store.state.office.currentLoanLender.id_borrower,
      penyelenggara: store.state.office.currentLoanLender.id_penyelenggara,
      noPerjanjianLender:
        store.state.office.currentLoanLender.no_perjanjian_lender,
      lender: store.state.office.currentLoanLender.id_lender,
      tanggalPerjanjianLender: store.state.office.currentLoanLender.tgl_perjanjian_lender.substring(
        0,
        10
      ),
      tanggalPenawaranPemberianPinjaman: store.state.office.currentLoanLender.tgl_penawaran_pemberian_pinjaman.substring(
        0,
        10
      ),
      noVaLender: store.state.office.currentLoanLender.no_va_lender,
      nilaiPenawaranPinjaman:
        store.state.office.currentLoanLender.nilai_penawaran_pinjaman,
      nilaiPenawaranDisetujui:
        store.state.office.currentLoanLender.nilai_penawaran_disetujui,
    };
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-user");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async submitLoanLender() {
      this.uploadedFile = "";
      this.loading = true;

      try {
        await store
          .dispatch("office/editLoanLender", {
            id: this.$route.params.id,
            data: this.formNewLoanLender,
          })
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
